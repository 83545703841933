const orderLangEng = {
    orderDetails: "Order details",
    selectTableNum: "Please select a table number",
    selectRoomNum: "Please select a Room number",

    select: "Select",
    roomNum: "Room Number",
    teeNum: "Tee Number",
    tableNum: "Table Number",
    room: "Room",
    tee: "Tee",
    table: "Table",
    roomNumTitle: "Please enter your Room number",
    whereSeated: "Where are you seated?",
    addNotes: "Add notes to your order",
    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
    writeSomething: "Write something you want here",
};

const orderLangThai = {
    orderDetails: "Order details",
    selectTableNum: "Please select a table number",
    selectRoomNum: "Please select a Room number",

    select: "Select",
    roomNum: "Room Number",
    teeNum: "Tee Number",
    tableNum: "Table Number",
    room: "Room",
    tee: "Tee",
    table: "Table",
    addNotes: "Add notes to your order",
    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
    writeSomething: "Write something you want here",
};

export const orderLang = {
    eng: orderLangEng,
    thai: orderLangThai,
};
