import { Dispatch } from "redux";
import api from ".";
import { getTag } from "../Action/tag.action";

export const getAllTags = (id: any, zone: any) => (dispatch: Dispatch) => {
    api.get(`/tag/list/${id}?zone=${zone}`)
        .then((response) => {
            dispatch(getTag(response.data.data));
        })
        .catch((err) => {});
};
