import { detailProductAction, loaderAction } from "../const";
import { DetailProductType} from "../Type/detailproduct.type";
export interface DetailProductReducer {
    detailProduct: DetailProductType;
    loader: boolean;
}
const initialState: DetailProductReducer = {
    detailProduct: {} as DetailProductType,
    loader: true,
};
const detailProductReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case detailProductAction.GET_DETAILPRODUCT:
            return {
                ...state,
                detailProduct: payload,
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default detailProductReducer;
