import { Dispatch } from "redux";
import api from ".";
import { getGuestReqCategory } from "../Action/category.action";
import { displayLoader } from "../Action/loader.action";

export const getAllGuestRequestCategories =
    (slug: any) => (dispatch: Dispatch) => {
        api.get(`/house-category/list/${slug}`)
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(
                    getGuestReqCategory({
                        ...response?.data?.data?.categories[0],
                        currency: response.data?.data?.currency,
                    })
                );
            })
            .catch((err) => {
                dispatch(displayLoader(false));
            });
    };
