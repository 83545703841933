import { Formik } from "formik";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ApiFeedbackOrder } from "../../store/api/feedback";
import AppButton from "../AppButton";
import { StateType } from "../../store/Reducer/index.reducer";
import { displayLoader } from "../../store/Action/loader.action";
import Loader from "../Loader";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";

const Feedback = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<any>();
    const { currentVenue, zone } = useParams();
    let navigate = useNavigate();
    const ref: any = useRef();
    let data = new FormData();
    const { loader } = useSelector((state: StateType) => state.feedback);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        restaurant: currentVenue || "",
    });
    const handleSubmit = async (values: any) => {
        setFormData(values);
        const toSend = {
            name: values.name,
            email: values.email,
            message: values.message,
            slug: currentVenue || "",
        };
        for (const [key, value] of Object.entries(toSend)) {
            data.append(key, value);
        }
        dispatch(displayLoader(true));
        currentVenue &&
            dispatch(
                ApiFeedbackOrder(data, {
                    currentVenue,
                    zone,
                    navigate: navigate,
                })
            );
        if (ref?.current) ref?.current.resetForm();
    };
    const feedbackInfo = {
        name: "",
        email: "",
        message: "",
    };
    const validationSchema = Yup.object({
        name: Yup.string()
            .trim()
            .min(4, "Name must be atleast 4 letters")
            .required("Please enter your name!"),
        email: Yup.string()
            .email("Invalid email!")
            .required("Please enter your email!"),
        message: Yup.string()
            .trim()
            .min(10, "Review must be atleast 10 letters")
            .required("Please enter your message!"),
    });

    return (
        <>
            <Navbar showArrow showLogo={true} venue={currentVenue} />
            <div className="feedback_wrapper">
                <Formik
                    innerRef={ref}
                    initialValues={feedbackInfo}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                    }) => {
                        const { name, email, message } = values;
                        return (
                            <form
                                className="feedback_container"
                                onSubmit={handleSubmit}
                            >
                                <div>
                                    <h1>{t("feedback:sendFeedback")}</h1>
                                    <div className="feedback_inputfield">
                                        <label htmlFor="name">
                                            {t("feedback:form.name.label")}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={`${t(
                                                "feedback:form.name.placeholder"
                                            )}`}
                                            onChange={handleChange("name")}
                                            name="name"
                                            value={name}
                                        />
                                        {touched.name && errors.name && (
                                            <p className="errmsg">
                                                {errors.name}
                                            </p>
                                        )}
                                    </div>
                                    <div className="feedback_inputfield">
                                        <label htmlFor="email">
                                            {t("feedback:form.email.label")}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={`${t(
                                                "feedback:form.email.placeholder"
                                            )}`}
                                            onChange={handleChange("email")}
                                            name="email"
                                            value={email}
                                        />
                                        {touched.email && errors.email && (
                                            <p className="errmsg">
                                                {errors.email}
                                            </p>
                                        )}
                                    </div>
                                    <div className="feedback_descriptionfield">
                                        <label htmlFor="message">
                                            {t("feedback:form.message.label")}
                                        </label>
                                        <textarea
                                            className="description_textarea"
                                            placeholder={`${t(
                                                "feedback:form.message.placeholder"
                                            )}`}
                                            onChange={handleChange("message")}
                                            name="message"
                                            value={message}
                                        />
                                        {touched.message && errors.message && (
                                            <p className="errmsg">
                                                {errors.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <AppButton btnType="tertiary">
                                    {t("default:common.buttons.submit")}
                                    <span>
                                        {loader && (
                                            <Loader classType="btn-spinner" />
                                        )}
                                    </span>
                                </AppButton>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default Feedback;
