import React, { useEffect, useState } from "react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
    useLocation,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { HomeIMAGE_BASE_URL, MEDIA_IMAGE_URL } from "../../constant";
import { StateType } from "../../store/Reducer/index.reducer";
import { getProfileApi } from "../../store/api/resturantProfile";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppButton from "../AppButton";
import Loader from "../Loader";
import { Collapse, Drawer } from "antd";
import DrawerForm from "../DrawerForm";
import { useTranslation } from "react-i18next";

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};
const Index = (props: any) => {
    const dispatch = useDispatch<any>();
    let navigate = useNavigate();
    let query = useQuery();
    const { t } = useTranslation("restaurantProfile");
    const { venue, zone } = useParams();
    localStorage.getItem("zone");

    useEffect(() => {
        if (!zone) {
            navigate(`/menu/${venue}/dine-in`);
        }
    }, [venue, zone, navigate]);
    const [visible, setVisible] = useState(false);
    const [inputTableNum, setInputTableNum] = useState<string | number | null>(
        null
    );
    const [tablenum, setTableNum] = useState<string | number | null>(null);
    const [inputName, setInputName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");
    const [isOpensocial, setIsOpenSocial] = useState(false);
    const [submitType, setSubmitType] = useState<"change" | "submit">("change");
    const [activeBtn, setActiveBtn] = useState<
        "viewMenu" | "guestReq" | "directory" | "feedback" | "socials"
    >("viewMenu");

    const [isGuestClicked, setIsGuestClicked] = useState(false);
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const loader = useSelector((state: StateType) => state.profile.loader);

    const onSubmit = () => {
        if (isGuestClicked) {
            setIsGuestClicked(false);
            setTableNumber(inputTableNum);
            navigate(`/menu/${venue}/guest-request/category`, {
                replace: true,
            });
            return;
        }
        if (
            homeData?.data?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/${zone}/category`, { replace: true });
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
            return;
        }
        {
            setTableNumber(inputTableNum);
            submitType == "submit" &&
                navigate(`/menu/${venue}/${zone}/category`, { replace: true });
        }
    };
    const showDrawer = (type: "change" | "submit") => {
        setSubmitType(type);
        tablenum && type == "submit"
            ? navigate(`/menu/${venue}/${zone}/category`, { replace: true })
            : setVisible(true);
    };
    const showGuestDrawer = (type: "change" | "submit") => {
        setSubmitType(type);
        tablenum && type == "submit"
            ? navigate(`/menu/${venue}/guest-request/category`, {
                  replace: true,
              })
            : setVisible(true);
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };

    useEffect(() => {
        dispatch(getProfileApi(venue, zone));

        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, [venue]);
    let table = sessionStorage.getItem("tablenum");
    let user = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (user) {
            setInputName(user);
        }
        if (phonenum) {
            setPhoneNum(phoneNum);
        }
    }, [table, user, phonenum]);
    useEffect(() => {
        if (query.get("key")) {
            sessionStorage.setItem(
                "tablenum",
                JSON.stringify(query.get("key") ?? "")
            );
            setInputTableNum(query.get("key") ?? "");
            setTableNum(query.get("key") ?? "");
        }
    }, [query.get("key")]);
    const setTableNumber = (tableNum: string | number | null) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", phoneNum);
    };
    const handleSocialClose = () => {
        setIsOpenSocial(false);
    };
    const handleSocials = () => {
        setIsOpenSocial(!isOpensocial);
    };

    return (
        <>
            <Navbar showNavMenu={true} showLogo venue={venue} />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="landing_wrapper">
                    <div className="welcome-page container">
                        <div className="cover__image">
                            <img
                                src={
                                    homeData?.data?.profile?.banner ==
                                    "/images/banner/"
                                        ? "/Images/homecover.png"
                                        : `${MEDIA_IMAGE_URL}/images/banner/${homeData?.data?.profile?.banner}`
                                }
                                alt="restaurant-cover"
                            />
                        </div>
                        <div className="logo-wrapper">
                            <img
                                src={
                                    !homeData?.data?.profile?.logo
                                        ? "/Images/logoIII.png"
                                        : `${MEDIA_IMAGE_URL}/images/logo/${homeData?.data?.profile?.logo}`
                                }
                                alt="restaurant-logo"
                            />
                        </div>
                        <div className="home__content">
                            <div className="button_container">
                                <div className="content-box ">
                                    <div className="front-content">
                                        <div className="front-btn">
                                            <span className="table-num">
                                                {tablenum
                                                    ? `${
                                                          zone == "in-room" ||
                                                          zone ==
                                                              "guest-request"
                                                              ? t("room")
                                                              : t("table")
                                                      } ${tablenum}`
                                                    : `${
                                                          zone == "in-room" ||
                                                          zone ==
                                                              "guest-request"
                                                              ? t("selectRoom")
                                                              : t("selectTable")
                                                      }`}
                                            </span>
                                            <div
                                                className="table-btn "
                                                onClick={() =>
                                                    showDrawer("change")
                                                }
                                            >
                                                {tablenum
                                                    ? t(
                                                          "default:common.buttons.change"
                                                      )
                                                    : t(
                                                          "default:common.buttons.select"
                                                      )}
                                            </div>
                                        </div>
                                        <div
                                            className={`front-btn ${
                                                activeBtn === "viewMenu"
                                                    ? "touched"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                showDrawer("submit");
                                            }}
                                            onTouchStart={() => {
                                                setActiveBtn("viewMenu");
                                            }}
                                        >
                                            <span>
                                                {zone == "in-room"
                                                    ? t("viewMenuRoom")
                                                    : t("viewMenu")}
                                            </span>
                                            <span>
                                                <img
                                                    src="/Images/vm-icon.svg"
                                                    alt="viewmenu-icon"
                                                />
                                            </span>
                                        </div>
                                        {zone == "in-room" && (
                                            <div
                                                className={`front-btn ${
                                                    activeBtn === "guestReq"
                                                        ? "touched"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    setIsGuestClicked(true);
                                                    showGuestDrawer("submit");
                                                }}
                                                onTouchStart={() =>
                                                    setActiveBtn("guestReq")
                                                }
                                            >
                                                <span>{t("guestRequest")}</span>
                                                <span>
                                                    <img
                                                        src="/Images/vm-icon.svg"
                                                        alt="viewmenu-icon"
                                                    />
                                                </span>
                                            </div>
                                        )}
                                        <Link
                                            to={`/menu/${venue}/${zone}/directory-category`}
                                            className={`front-btn ${
                                                activeBtn === "directory"
                                                    ? "touched"
                                                    : ""
                                            }`}
                                            onTouchStart={() =>
                                                setActiveBtn("directory")
                                            }
                                        >
                                            <span>Digital Directory</span>
                                            <span>
                                                <img
                                                    src="/Images/feedback-icon.svg"
                                                    alt="icon"
                                                />
                                            </span>
                                        </Link>
                                        <Link
                                            to={`/menu/${venue}/${zone}/feedback`}
                                            className={`front-btn ${
                                                activeBtn === "feedback"
                                                    ? "touched"
                                                    : ""
                                            }`}
                                            onTouchStart={() =>
                                                setActiveBtn("feedback")
                                            }
                                        >
                                            <span>{t("feedback")}</span>
                                            <span>
                                                <img
                                                    src="/Images/feedback-icon.svg"
                                                    alt="icon"
                                                />
                                            </span>
                                        </Link>

                                        {homeData?.data?.socials?.length >=
                                            1 && (
                                            <div
                                                className={`front-btn ${
                                                    activeBtn === "socials"
                                                        ? "touched"
                                                        : ""
                                                }`}
                                                onTouchStart={() =>
                                                    setActiveBtn("socials")
                                                }
                                                onClick={handleSocials}
                                            >
                                                <span>{t("socialMedia")}</span>
                                                <span>
                                                    <img
                                                        src="/Images/social-icon.svg"
                                                        alt="icon"
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                    <Drawer
                                        title="Social Media"
                                        placement="bottom"
                                        onClose={handleSocialClose}
                                        open={isOpensocial}
                                        className="socials-drawer"
                                    >
                                        {homeData?.data?.socials?.map(
                                            (sData: any, i: number) => {
                                                return (
                                                    <a
                                                        href={sData.link}
                                                        target="_blank"
                                                        className="social_button"
                                                    >
                                                        {sData.name &&
                                                            sData.link !=
                                                                null && (
                                                                <button
                                                                    className={
                                                                        sData?.name ==
                                                                        "Facebook"
                                                                            ? "social_button fb_media"
                                                                            : sData?.name ==
                                                                              "Twitter"
                                                                            ? "social_button tw_media"
                                                                            : sData?.name ==
                                                                              "Instagram"
                                                                            ? "social_button ig_media"
                                                                            : sData?.name ==
                                                                              "Tiktok"
                                                                            ? "social_button tiktok_media"
                                                                            : sData?.name ==
                                                                              "Trip Advisor"
                                                                            ? "social_button td_media"
                                                                            : "social_button"
                                                                    }
                                                                    key={
                                                                        sData?.id
                                                                    }
                                                                >
                                                                    {
                                                                        sData?.name
                                                                    }
                                                                </button>
                                                            )}
                                                    </a>
                                                );
                                            }
                                        )}
                                    </Drawer>
                                </div>
                                <DrawerForm
                                    title={
                                        zone == "in-room" ||
                                        zone == "guest-request"
                                            ? t("roomNumTitle")
                                            : t("whereSeated")
                                    }
                                    label={`${
                                        zone == "in-room" ||
                                        zone == "guest-request"
                                            ? t("roomNum")
                                            : t("tableNum")
                                    }`}
                                    placeholder={
                                        zone == "in-room" ||
                                        zone == "guest-request"
                                            ? `${t("placeholder.roomNum")}`
                                            : `${t("placeholder.tableNum")}`
                                    }
                                    visible={visible}
                                    value={inputTableNum}
                                    onClose={() => {
                                        setIsGuestClicked(false);
                                        setVisible(false);
                                    }}
                                    onSubmit={onSubmit}
                                    onChangeOne={(e: any) =>
                                        setInputTableNum(e.target.value)
                                    }
                                    onChangeTwo={(e: any) => onChangeTwo(e)}
                                    onChangeThree={(e: any) => onChangeThree(e)}
                                    validationMsgOne={
                                        !inputTableNum ||
                                        homeData?.data?.profile?.table?.filter(
                                            (data: any) =>
                                                data.key == inputTableNum
                                        )?.length
                                            ? ""
                                            : `
                                             ${
                                                 zone == "in-room" ||
                                                 zone == "guest-request"
                                                     ? t("validation.roomNum")
                                                     : t("validation.tableNum")
                                             }`
                                    }
                                    validationMsgTwo={validationMsgTwo}
                                    validationMsgThree={validationMsgThree}
                                    buttondisabled={
                                        homeData?.data?.profile?.type == 3
                                            ? homeData?.data?.profile?.table?.filter(
                                                  (data: any) =>
                                                      data.key == inputTableNum
                                              )?.length &&
                                              inputName.length &&
                                              phoneNum
                                                ? false
                                                : true
                                            : !homeData?.data?.profile?.table?.filter(
                                                  (data: any) =>
                                                      data.key == inputTableNum
                                              )?.length
                                    }
                                />
                                <div className="footer_wrapper">
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cover_container">
                        <img src="/Images/homecover.png" />
                    </div>
                </div>
            )}
        </>
    );
};

export default Index;
