import React from 'react'
import { BsArrowRight } from 'react-icons/bs'


type ReadMore={
    url?:string,
    label?:string

}

const ReadMore = (props:ReadMore) => {
  const {url,label}=props;
  return (
    <>
        <a href={url} className="readmore_linkdata">
            {label}
            <BsArrowRight/>
        </a>
    </>
  )
}
export default ReadMore;
