const commonEng = {
    buttons: {
        close: "Close",
        confirm: "Confirm",
        confirmRequest: "Confirm Request",

        cancel: "Cancel",
        submit: "Submit",
        change: "Change",
        select: "Select",
        dineIn: "Dine-in",
        guestRequest: "Guest-Request",
        inRoom: "In-Room",
        poolSide: "Pool-Side",
        viewMenu: "View-Items",
        viewOrder: "View Order",
        viewOrdrGuestRequest: "View Request",
        apply: "Apply",
        addToOrder: "Add to Order",
        addItem: "Add Items",
        continue: "Continue",
    },
};

const commonThai = {
    buttons: {
        close: "Close",
        confirm: "Confirm",
        confirmRequest: "Confirm Request",

        cancel: "Cancel",
        submit: "Submit",
        change: "Change",
        select: "Select",
        dineIn: "Dine-in",
        guestRequest: "Guest-Request",
        inRoom: "In-Room",
        poolSide: "Pool-Side",
        viewMenu: "View-Items",
        viewOrder: "View Order",
        viewOrdrGuestRequest: "View Request",
        apply: "Apply",
        addToOrder: "Add to Order",
        addItem: "Add Items",
        continue: "Continue",
    },
};

export { commonEng, commonThai };
