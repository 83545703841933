import { loaderAction, menuAction } from "../const";
import { MenuType } from "../Type/menu.type";

export interface MenuReducer {
    menu: MenuType;
    loader: boolean;
}
const inititalState: MenuReducer = {
    menu: {} as MenuType,
    loader: true,
};

const menuReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case menuAction.GET_MENU:
            return {
                ...state,
                menu: payload,
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default menuReducer;
