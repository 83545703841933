import { useState, useEffect } from "react";
import { Button, Drawer, Form, Input, InputNumber } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { useTranslation } from "react-i18next";

interface Props {
    label: string;
    title: string;
    placeholder: string;
    visible?: boolean;
    value?: any;
    onClose: () => void;
    onChangeOne: (e: any) => void;
    onChangeTwo: (e: any) => void;
    onChangeThree: (e: any) => void;
    onSubmit: () => void;
    buttondisabled?: boolean;
    validationMsgOne?: string;
    validationMsgTwo?: any;
    validationMsgThree?: any;
}

const DrawerForm = ({
    label,
    title,
    placeholder,
    visible,
    value,
    onClose,
    onChangeOne,
    onChangeTwo,
    onChangeThree,
    onSubmit,
    buttondisabled,
    validationMsgOne,
    validationMsgTwo,
    validationMsgThree,
}: Props) => {
    const { t } = useTranslation("drawerForm");
    const homeData = useSelector((state: StateType) => state.profile.profile);
    let inputShow =
        homeData?.data?.profile?.type == 3 ||
        homeData?.data?.profile?.type == 4;
    const makeOption = () => {
        const options =
            homeData?.data?.profile?.table?.map((data: any, index: any) => {
                return (
                    <option key={index} value={data.key}>
                        {data.key}
                    </option>
                );
            }) || [];
        return options;
    };
    const [placement, setPlacement] =
        useState<DrawerProps["placement"]>("bottom");
    const onFinish = (values: any) => {};

    const on_Submit = () => {
        onSubmit();
        onClose();
    };

    return (
        <div>
            <Drawer
                title={title}
                placement={placement}
                width={500}
                onClose={onClose}
                visible={visible}
                className="table__drawer"
            >
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                    className="table__form"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onSubmit();
                            !validationMsgOne && onClose();
                        }
                    }}
                >
                    <Form.Item label={label} name="tablenum">
                        <Input
                            placeholder={placeholder}
                            type="text"
                            value={value}
                            onChange={onChangeOne}
                        />
                        {validationMsgOne && (
                            <p className="drawer_validationmsg">
                                {validationMsgOne}
                            </p>
                        )}
                    </Form.Item>

                    {inputShow && (
                        <Form.Item
                            label="Full Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: `${t(
                                        "validation.provideFullName"
                                    )}`,
                                },
                            ]}
                        >
                            <Input
                                placeholder={`${t("placeholder.booking")}`}
                                onChange={onChangeTwo}
                                name="phone"
                            />
                            {validationMsgTwo && (
                                <p className="drawer_validationmsg">
                                    {validationMsgTwo}
                                </p>
                            )}
                        </Form.Item>
                    )}
                    {inputShow && (
                        <Form.Item
                            label={`${t("contactNum")}`}
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: `${t("validation.contactNum")}`,
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder={`${t("placeholder.contactNum")}`}
                                onChange={onChangeThree}
                            />
                            {validationMsgThree && (
                                <p className="drawer_validationmsg">
                                    {validationMsgThree}
                                </p>
                            )}
                        </Form.Item>
                    )}
                </Form>
                <Form.Item>
                    <Button
                        onClick={on_Submit}
                        htmlType="submit"
                        disabled={buttondisabled}
                        className={buttondisabled ? "disabledBtn" : ""}
                    >
                        {t("default:common.buttons.continue")}
                    </Button>
                </Form.Item>
            </Drawer>
        </div>
    );
};

export default DrawerForm;
