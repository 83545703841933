const restaurantProfileLangEng = {
    room: "Room",
    tee: "Tee",
    table: "Table",
    feedback: "Feedback",
    viewMenu: "View Menu",
    viewMenuRoom: "Order Room Service",
    socialMedia: "Social Media ",
    selectRoom: "Select Room Number",
    selectTee: "Select Tee Number",
    selectTable: "Select Table Number",
    roomNumTitle: "Please enter your Room number",
    tableNum: "Table Number",
    roomNum: "Room Number",
    whereSeated: "Where are you seated?",
    guestRequest: "Guest Request",

    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
};

const restaurantProfileLangThai = {
    room: "Room",
    tee: "Tee",
    table: "Table",
    feedback: "Feedback",
    viewMenu: "View Menu",
    viewMenuRoom: "Order Room Service",

    socialMedia: "Social Media ",
    selectRoom: "Select Room Number",
    selectTee: "Select Tee Number",
    selectTable: "Select Table Number",
    roomNumTitle: "Please enter your Room number",
    guestRequest: "Guest Request",

    tableNum: "Table Number",
    RoomNum: "Room Number",
    whereSeated: "Where are you seated?",

    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
};

export const restaurantProfileLang = {
    eng: restaurantProfileLangEng,
    thai: restaurantProfileLangThai,
};
