import React from "react";
import { FaHeart } from "react-icons/fa";
import { IoIosPeople, IoMdPerson } from "react-icons/io";
import ReadMore from "../ReadMore";

type CardProps = {
    children?: any;
};
const AppCard = (props: CardProps) => {
    const TopCard = [
        {
            icon: <img src="../Images/cardiconIII.svg" />,
            cardHeading: "Combat the labor shortage",
            cardParagraph:
                "Provide an efficient, flexible service model that will help eliminate long lines, reduce wait times, and simplify checkout for guests and staff.",
            label: "Learn More",
            duration: 2000,
        },
        {
            icon: <img src="../Images/cardiconII.svg" />,
            cardHeading: "Increase Revenue & Check Size",
            cardParagraph:
                "Increase revenue by 10%¹ and grow check size by 9%².",
            label: "Learn More",
            duration: 4000,
        },
        {
            icon: <img src="../Images/cardiconI.svg" />,
            cardHeading: "Collect Valuable Guest Data",
            cardParagraph:
                "Collect guest data on each transaction that can be leveraged to power your marketing and loyalty programs.",
            label: "Learn More",
            duration: 6000,
        },
    ];
    const { children } = props;
    return (
        <>
            <div className="main_card">
                <div className="l-topcardwrapper container_wrap">
                    <img
                        src="/Images/L-FoodIII.png"
                        className="imageicon imageiconIII"
                        alt="bread-img"
                    />
                    <img
                        src="/Images/L-FoodIV.png"
                        className="imageicon imageiconIV"
                        alt="bread-img"
                    />
                    <div className="lcard_content">
                        <h2 className="landing_headingII">
                            Why 0ver 57,000 Restaurants Love Toast
                        </h2>
                        <p className="landing_paragraph">
                            Grow your business online with your own
                            commission-free ordering channels for takeout and
                            delivery- whether that’s on your website, the Toast
                        </p>
                    </div>
                    <div className="lcard_box">
                        {/* <div className="l-topcardwrapper"> */}
                        {TopCard.map((data, index) => {
                            return (
                                <>
                                    <div
                                        className="landingtopcard appCard"
                                        key={index}
                                    >
                                        <div className="card_icon">
                                            {data.icon}
                                        </div>
                                        <div className="appcard_content">
                                            <h3 className="heading-III">
                                                {data.cardHeading}
                                            </h3>
                                            <p>{data.cardParagraph}</p>
                                        </div>
                                        <ReadMore label={data.label} />
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
export default AppCard;
