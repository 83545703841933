import React, { useState } from "react";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import Navbar from "../Navbar";
import Pricing from "../Pricing";

const Index = () => {
    const [customerName, setCustomerName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const nameVerification = (e: any) => {
        setCustomerName(e.target.value);
    };
    const validRegix = /\S+@\S+\.[a-zA-Z]+$/;
    const emailVerification = (e: any) => {
        setCustomerEmail(e.target.value);
        if (validRegix.test(e.target.value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    };
    return (
        <>
            <Navbar showArrow showDineIn />
            <div className="payment_container">
                <form className="payment__form">
                    <h2>Your details</h2>
                    <div className="payment__formgroup">
                        <label>Name</label>
                        <input
                            type="text"
                            name="customerName"
                            onChange={(e) => nameVerification(e)}
                        />
                        {!customerName.length && (
                            <p id="email-validation">Please enter your name</p>
                        )}
                    </div>
                    <div className="payment__formgroup">
                        <label>Please enter your email address</label>
                        <input
                            type="text"
                            onChange={(e) => emailVerification(e)}
                        />
                        {!validEmail && (
                            <p id="email-validation">
                                Please provide your email address
                            </p>
                        )}
                    </div>
                    <div className="paymentmethod_wrapper">
                        <h3>Select your payment method</h3>
                        <div className="payment_method">
                            <div className="payment_category">
                                <div>Cash</div>
                                <h6>Cash</h6>
                            </div>
                            <input type="checkbox" />
                        </div>
                    </div>
                </form>
            </div>
            <div className="ordertable_footer">
                <Pricing />
                <div className="payment__btn">
                    <button>Continue</button>
                </div>
            </div>
        </>
    );
};

export default Index;
