import { Dispatch } from "redux";
import api from ".";
import {
    getDetailDirectoryItem,
    getDetailProduct,
} from "../Action/detailProduct.action";
import { displayLoader } from "../Action/loader.action";

export const getAllDetailDirectoryItem =
    (id: string) => (dispatch: Dispatch) => {
        api.get(`/digital-directory/single-product/${id}`)
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(getDetailDirectoryItem(response.data));
            })
            .catch((err) => {
                dispatch(displayLoader(false));
            });
    };
