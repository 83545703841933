import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "antd/dist/antd.css";
import ResturantProfile from "./components/ResturantProfile";
import "../src/styles/main.scss";
import Verification from "./pages/Verification/index";
import OtpConfirmation from "./pages/OtpConfirmation";
import Category from "./components/Category";
import Payment from "./components/Payment";
import Empty from "./components/Empty";
import Greeting from "./pages/Greeting";
import ListingMenu from "./components/ListingMenu";
import DetailMenu from "./components/DetailMenu";
import { Provider } from "react-redux";
import store from "./store/store";
import Order from "./pages/Order";
import Feedback from "./components/Feedback/Feedback";
import LandingPage from "./pages/LandingPage";
import Receipt from "./components/Receipt/receipt";
import Signup from "./components/Signup/SignUp";
import Verify from "./components/Signup/Verify";
import AboutUser from "./components/Signup/AboutUser";
import AboutCompany from "./components/Signup/AboutCompany";

import Privacy from "./components/Terms/Privacy";
import Terms from "./components/Terms/Terms";
import TermsPrivacy from "./pages/TermsPrivacy";
import AboutBusiness from "./components/Signup/AboutBusiness";
import Plan from "./pages/Plan";
import Login from "./components/Login";
import FailedToVerify from "./components/Signup/FailedToVerify";
import VerifyEmail from "./components/Signup/VerifyEmail";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import GuestRequest from "./pages/GuestRequest";
import DirectoryCategory from "./pages/Directory/directoryCategory";
import DirectoryDetail from "./pages/Directory/directoryDetail";
function App(props: any) {
    AOS.init({
        // duration: 3000,
    });
    return (
        <>
            <I18nextProvider i18n={i18n} defaultNS={"translation"}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route
                                path="menu/:currentVenue/:zone/category"
                                element={<Category />}
                            ></Route>
                            <Route
                                path="menu/:currentVenue/guest-request/category"
                                element={<GuestRequest />}
                            ></Route>
                            <Route
                                path="menu/:currentVenue/:zone/directory-category"
                                element={<DirectoryCategory />}
                            ></Route>
                            <Route
                                path="/menu/:currentVenue/:zone/directory-detail/:id"
                                element={<DirectoryDetail />}
                            ></Route>
                            {/* menu/:currentVenue/:zone/detailmenu/:id */}
                            <Route
                                path="menu/:venue/:zone?"
                                element={
                                    <ResturantProfile zoneDefault="dine-in" />
                                }
                            />

                            <Route
                                path="menu/:currentVenue/:zone/receipt"
                                element={<Receipt />}
                            />
                            <Route path="terms" element={<Terms />} />
                            <Route path="privacy" element={<Privacy />} />
                            <Route
                                path="terms-privacy"
                                element={<TermsPrivacy />}
                            />
                            <Route
                                path="virtual-menu/login"
                                element={<Login />}
                            />

                            <Route
                                path="virtual-menu/signup"
                                element={<Signup />}
                            />

                            <Route
                                path="/virtual-menu/verify-email"
                                element={<VerifyEmail />}
                            />
                            <Route
                                path="/virtual-menu/verified"
                                element={<Verify />}
                            />
                            <Route
                                path="/virtual-menu/failed-to-verify"
                                element={<FailedToVerify />}
                            />
                            <Route
                                path="/virtual-menu/about-business"
                                element={<AboutBusiness />}
                            />
                            <Route
                                path="virtual-menu/about-user"
                                element={<AboutUser />}
                            />
                            <Route
                                path="/virtual-menu/about-restaurant"
                                element={<AboutCompany />}
                            />
                            <Route
                                path="/virtual-menu/plan"
                                element={<Plan />}
                            />
                            <Route
                                path="verification"
                                element={<Verification />}
                            ></Route>
                            <Route
                                path="otp-confirmation"
                                element={<OtpConfirmation />}
                            ></Route>

                            <Route
                                path="menu/:currentVenue/:zone/order"
                                element={<Order />}
                            ></Route>
                            <Route
                                path="menu/:currentVenue/:zone/listingmenu/:id/:restaurantid"
                                element={<ListingMenu />}
                            ></Route>
                            <Route
                                path="menu/:currentVenue/:zone/detailmenu/:id"
                                element={<DetailMenu />}
                            ></Route>
                            <Route path="payment" element={<Payment />}></Route>
                            <Route
                                path="menu/:currentVenue/:zone/greeting"
                                element={<Greeting />}
                            ></Route>
                            <Route
                                path="menu/:currentVenue/:zone/feedback"
                                element={<Feedback />}
                            ></Route>
                            <Route path="empty" element={<Empty />}></Route>
                        </Routes>
                    </BrowserRouter>
                </Provider>
            </I18nextProvider>
        </>
    );
}
export default App;
