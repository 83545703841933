import React, { useEffect, useState } from "react";
import AppButton from "../../components/AppButton";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { getAllDetailDirectoryItem } from "../../store/api/detailDirectory";
import { MEDIA_IMAGE_URL } from "../../constant";
import { directoryRequirement } from "../../store/Type/directoryDetail.type";

const DirectoryDetail = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { id, currentVenue } = useParams();
    const dispatch = useDispatch<any>();

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const directoryDetails = useSelector(
        (state: StateType) => state.detailDirectoryItem?.detailDirectoryItem
    );
    useEffect(() => {
        id && dispatch(getAllDetailDirectoryItem(id));
    }, []);

    return (
        <>
            <div className="directory_detail">
                <div className="directory_detailcover">
                    <img
                        // onError={({ currentTarget }) => {
                        //     currentTarget.onerror = null;
                        //     currentTarget.src = "/Images/catcover.png";
                        // }}
                        src={`${MEDIA_IMAGE_URL}/images/digital-product/${directoryDetails?.data?.image}`}
                        alt="directory_cover"
                    />
                    <div className="detailmenu__arrow" onClick={goBack}>
                        <BsArrowLeftShort className="arrow-left" />
                    </div>
                    <div>
                        <span>{directoryDetails?.data?.name}</span>
                        <span>{directoryDetails?.data?.subtitle}</span>
                    </div>
                </div>
                <div className="detail_contentwrapper">
                    <div className="detail_content">
                        <div className="detal_contdesc">
                            {isExpanded
                                ? directoryDetails?.data?.description
                                : `${directoryDetails?.data?.description.slice(
                                      0,
                                      80
                                  )}
                            ${
                                directoryDetails?.data?.description.length > 80
                                    ? "..."
                                    : ""
                            }`}
                            {directoryDetails?.data?.description.length >
                                80 && (
                                <span onClick={toggleReadMore}>
                                    {isExpanded ? "Read Less" : "Read More"}
                                </span>
                            )}
                        </div>
                        <div className="detail_contentlists">
                            {directoryDetails?.data?.requirements?.map(
                                (data: directoryRequirement, index: number) => {
                                    return (
                                        <div
                                            className="detail_listblock"
                                            key={index}
                                        >
                                            <div className="icon">
                                                {data.icon}
                                            </div>
                                            <div className="data">
                                                <span>{data.name}</span>
                                                <span>{data.description}</span>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>

                    <AppButton btnType="tertiary" onClick={goBack}>
                        Back
                    </AppButton>
                </div>
            </div>
        </>
    );
};

export default DirectoryDetail;
