import { AiFillInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import AppButton from "../AppButton";
const FooterList = [
    {
        title: "Product",
        list: ["Features", "Pricing", "Case Studies", "Reviews", "Updates"],
    },
    {
        title: "Company",
        list: ["About", "Contact us", "Careers", "Culture"],
    },
    {
        title: "Support",
        list: [
            "Getting started",
            "Help center",
            "Server status",
            "Report a bug",
            "Chat support",
        ],
    },
    {
        title: "Downloads",
        list: ["ios", "Android", "Mac", "Windows", "Chrome"],
    },
];
const LandingFooter = () => {
    return (
        <div className="landing-footer container_wrap">
            <img
                src="../Images/bread.svg"
                className="bread_footer"
                alt="bread-img"
            />
            <div className="landing-topfooter">
                <img src="Images/logoII.png" />
                <p>
                    Passionate about the restaurant industry? Us, too. View open
                    roles or join our talent community.
                </p>
            </div>
            <div className="landing-footerlistwrapper">
                {FooterList?.map((data, index) => {
                    return (
                        <>
                            <div className="landing-bottomfooter" key={index}>
                                <h2 className="footer-title">{data.title}</h2>
                                <div className="footer-list">
                                    {data?.list?.map((listdata, index) => {
                                        return (
                                            <ul key={index}>
                                                <a href="/">
                                                    <li className="link_ctn">
                                                        {listdata}
                                                    </li>
                                                </a>
                                            </ul>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    );
                })}
                <div className="subscribe_section">
                    <h2 className="footer-title">
                        Subscribe to our newsletter
                    </h2>
                    <p>
                        Subscribe to our newsletter to get daily updates,
                        directly at your email.
                    </p>
                    <div className="subscribe_field">
                        <input type="text" placeholder="Enter your email" />
                        <AppButton btnType="inputbtn">Subscribe</AppButton>
                    </div>
                </div>
            </div>
            <div className="copyright-section ">
                <p>Copyright © 2022 ORDR | All Rights Reserved </p>
                <Link to="/privacy" className="link_ctn privacy_ctn">
                    Privacy Policy
                </Link>
                <Link to="/terms" className="link_ctn privacy_ctn">
                    Terms of Service and Service Agreement
                </Link>
                <div className="landing_socialicons">
                    <FaFacebookF size={20} />
                    <BsTwitter size={20} />
                    <AiFillInstagram size={20} />
                    <FaLinkedinIn size={20} />
                    <FaYoutube size={20} />
                </div>
            </div>
        </div>
    );
};

export default LandingFooter;
