import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from "./translations";

const geti18nLanguage = () => {
    try {
        return atob(atob(localStorage.getItem(btoa(btoa('internationalization'))) || ''));
    } catch (e) {
        return '';
    }
};

const seti18nLanguage = (languageType: "eng" | "thai") => {
    try {
        return localStorage.setItem(btoa(btoa('internationalization')), btoa(btoa(languageType)));
    } catch (e) {
        return '';
    }
};
  
export const switchI18nLanguage = (payload: "eng" | "thai" = "eng") => {
seti18nLanguage(payload);
i18n.changeLanguage(payload);
};

i18n
  .use(initReactI18next)
  .init({
    lng: geti18nLanguage() || 'eng',
    resources: translations,
    keySeparator: '.',
    defaultNS: 'common',
    fallbackLng: 'eng',
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;