export const ResturantProfileAction = {
    GET_RESPROFILE: "GET_RESPROFILE",
};
export const categoryAction = {
    GET_CATEGORY: "GET_ALL_CATEGORY",
    GET_HOUSEKEEPING_CATEGORY: "GET_ALL_HOUSEKEEPING_CATEGORY",
    GET_DIRECTORY_CATEGORY: "GET_ALL_DIRECTORY_CATEGORY",
};

export const receiptAction = {
    GET_RECEIPT: "GET_RECEIPT",
};
export const menuAction = {
    GET_MENU: "GET_ALL_MENU",
};
export const loaderAction = {
    GET_LOADER: "Get_LOADER",
};
export const detailProductAction = {
    GET_DETAILPRODUCT: "GET_DETAILPRODUCT",
    GET_DETAILDIRECTORYITEM: "GET_DETAILDIRECTORYITEM",
};

//cart
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART = "GET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
//visual-menu
export const V_SIGN_UP = "V_SIGN_UP";
export const V_LOGIN = "V_LOGIN";
export const V_USER = "V_USER";
export const V_RESTAURANT = "V_RESTAURANT";
export const V_TOKEN = "V_TOKEN";
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const BUSINESS_TYPE = "BUSINESS_TYPE";

export const tagAction = {
    GET_TAG: "GET_TAG",
};
export const SOCIAL_KEY = {
    GOOGLE_APPID:
        "644865092916-os29c3uujkt8iutetssgqm5id511516j.apps.googleusercontent.com",
};
export const orderAction = {
    CREATE_ORDER: "CREATE_ORDER",
    GET_ORDER: "GET_ORDER",
};
export const CURRENCY = "$";
export const feedbackAction = {
    FEEDBACK_ACTION: "FEEDBACK_ACTION",
};
