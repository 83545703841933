import { categoryAction, loaderAction } from "../const";
import { CategoryResType, CategoryType } from "../Type/category.type";

export interface CategoryReducer {
    category: Array<CategoryType>;
    categoryRes: CategoryResType;
    loader: boolean;
}
const inititalState: CategoryReducer = {
    category: [],
    categoryRes: {} as CategoryResType,
    loader: false,
};

const categoryReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case categoryAction.GET_CATEGORY:
            return {
                ...state,
                category: payload.categories,
                categoryRes: { ...payload, currency: payload.currency ?? "$" },
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default categoryReducer;
