import React, { useState } from "react";
import Loader from "../Loader";

type ButtonProps = {
    className?: any;
    children?: React.ReactNode;
    btnType?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "white-outlined"
        | "black-outlined"
        | "smbutton"
        | "lgbutton"
        | "black-heavyborder"
        | "inputbtn";

    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    htmlType?: "submit" | "button" | "reset";
    disabled?: boolean;
};
const AppButton = (props: ButtonProps) => {
    const { children, btnType, onClick, disabled, htmlType } = props;
    const [isTouched, setIsTouched] = useState(false);
    const handleTouchStart = () => {
        setIsTouched(true);
    };

    const handleTouchEnd = () => {
        setIsTouched(false);
    };
    return (
        <>
            <button
                className={`touch-button btn ${
                    isTouched
                        ? `btn-${btnType} touched`
                        : disabled
                        ? "btn-disabled"
                        : `btn-${btnType}`
                }`}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={onClick}
                disabled={disabled}
                type={htmlType}
            >
                {children}
            </button>
        </>
    );
};
export default AppButton;
