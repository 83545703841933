import { AiFillFire } from "react-icons/ai";
import text_truncate from "../TextTruncate";
import { CURRENCY } from "../../store/const";
import { useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";

interface Ilisting {
    imgsrc?: string;
    sold?: string;
    photo?: string;
    is_popular?: string;
    is_hot?: string;
    item_name: string;
    item_mrp?: string;
    tags?: any;
    description: string;
    request_btn?: boolean;
    price?: boolean;
    handleAdd?: any;
    subCat_id?: any;
    selectedTag?: any;
}
const ListingBlock = (props: Ilisting) => {
    const {
        imgsrc,
        sold,
        is_popular,
        is_hot,
        item_name,
        item_mrp,
        tags,
        description,
        request_btn,
        price,
        handleAdd,
        selectedTag,
    } = props;
    const [isTouched, setIsTouched] = useState(false);

    const handleTouchStart = () => {
        setIsTouched(true);
    };

    const handleTouchEnd = () => {
        setIsTouched(false);
    };

    return (
        <>
            <div className={imgsrc ? "menu_itemsactive" : "menu_items"}>
                {sold && (
                    <div className={imgsrc ? "sold_out" : "soldinactive"}></div>
                )}
                {imgsrc && (
                    <div className="listingproduct_img">
                        <img
                            src={imgsrc ? imgsrc : "/Images/noimg-1.svg"}
                            alt="menu"
                        />
                    </div>
                )}

                <div
                    className={
                        !imgsrc
                            ? "menu-wholecontent"
                            : sold
                            ? "menuinnercontent_opacity"
                            : "menu_inner_content"
                    }
                >
                    <div>
                        {(is_popular || is_hot) && (
                            <div className="subcat_status">
                                {is_popular ? (
                                    <div className="menu_status">Popular</div>
                                ) : (
                                    ""
                                )}
                                {is_hot ? (
                                    <div className="aifire">
                                        <AiFillFire
                                            className="aifire-icon"
                                            color="#004ef9"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}

                        <div className="menu_subcontentI">
                            <h4>
                                {text_truncate({
                                    str: item_name,
                                    length: 20,
                                    ending: null,
                                })}
                            </h4>

                            <p
                                className={
                                    !imgsrc
                                        ? "menulist_para active active"
                                        : is_popular || is_hot
                                        ? "menulist_popularp"
                                        : "menulist_para"
                                }
                            >
                                {description}
                            </p>
                        </div>
                    </div>
                    <div className="menu_subcontent">
                        {price && (
                            <div className="price">
                                {CURRENCY?.replace(/-/g, " ")}
                                &nbsp;
                                {item_mrp}
                            </div>
                        )}
                        {request_btn && (
                            <Link to="#">
                                <button
                                    className={`guestreq_btn ${
                                        isTouched ? "touched_reqBtn" : ""
                                    }`}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                    onClick={handleAdd}
                                >
                                    Request
                                </button>
                            </Link>
                        )}

                        {tags && (
                            <div
                                className={
                                    !imgsrc ? "menu__GVD active" : "menu__GVD"
                                }
                            >
                                {selectedTag
                                    ?.slice(0, 2)
                                    ?.map((tag: any, index: number) => {
                                        return (
                                            <span key={index}>
                                                {tag?.tag?.name}
                                            </span>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListingBlock;
