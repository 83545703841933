const pricingLangEng = {
    subtotal: "Subtotal",
    containerCharge: "Container Charge",
    serviceCharge: "Service Charge",
    beforeVat: "Before Tax",
    tax: "Tax",
    total: "Total",
};

const pricingLangThai = {
    subtotal: "Subtotal",
    containerCharge: "Container Charge",
    serviceCharge: "Service Charge",
    beforeVat: "Before Tax",
    tax: "Tax",
    total: "Total",
};

export const pricingLang = {
    eng: pricingLangEng,
    thai: pricingLangThai,
};
