import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { countryList } from "../../Helpers/countryList";

type PropsType = {
    mode?: string;
    handleChange?: any;
};

const Country = (props: PropsType) => {
    const { mode, handleChange } = props;
    return (
        <div>
            <select
                placeholder="Select "
                className="select_input"
                onChange={(e) => handleChange(e.target.value, mode)}
            >
                <option value="" disabled selected hidden>
                    {mode == "country_name" ? "- Select your country -" : "+95"}
                </option>
                {mode == "country_num" && (
                    <option value="dsajasdhj" disabled selected hidden>
                        +95
                    </option>
                )}

                {countryList?.map((data: any, i: number) => {
                    return (
                        <>
                            <option
                                value={
                                    mode == "country_name"
                                        ? data?.country_name
                                        : data?.dialling_code
                                }
                            >
                                {mode == "country_name"
                                    ? data?.country_name
                                    : data?.dialling_code}
                            </option>
                        </>
                    );
                })}
            </select>
        </div>
    );
};

export default Country;
