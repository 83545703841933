const listingMenuLangEng = {
    dietaryOptions: "Dietary options",
    drawerWithExtraActions: "Drawer with extra actions"
};

const listingMenuLangThai = {
    dietaryOptions: "Dietary options",
    drawerWithExtraActions: "Drawer with extra actions"
};

export const listingMenuLang = {
    eng: listingMenuLangEng,
    thai: listingMenuLangThai,
};
