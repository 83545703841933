import React, { useEffect } from "react";
import { BsArrowLeftShort, BsCheckLg } from "react-icons/bs";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton";
import { HomeIMAGE_BASE_URL, MEDIA_IMAGE_URL } from "../../constant";
import { getAllCategories } from "../../store/api/category";
import { getProfileApi } from "../../store/api/resturantProfile";
import { StateType } from "../../store/Reducer/index.reducer";

const Index = () => {
    const dispatch: any = useDispatch();
    const { currentVenue, zone } = useParams();
    const date = new Date();
    const navigate = useNavigate();
    const homeData = useSelector((state: StateType) => state.profile?.profile);
    const totalno = useSelector(
        (state: any) => state.order?.order?.data?.order?.total
    );
    const currency = useSelector(
        (state: StateType) => state.category?.categoryRes?.currency
    );
    const device_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    useEffect(() => {
        dispatch(getProfileApi(currentVenue, zone));
        dispatch(getAllCategories(currentVenue, zone, device_time));
    }, [dispatch]);
    const goBack = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="successfully_ordercontainer">
                <div className="ordr_endcover">
                    <div className="detailmenu__arrow" onClick={goBack}>
                        <BsArrowLeftShort className="arrow-left" />
                    </div>

                    <img
                        // src={"/Images/catcover.png"}
                        src={
                            !homeData?.data?.profile?.banner
                                ? "/Images/catcover.png"
                                : `${MEDIA_IMAGE_URL}/images/banner/${homeData?.data?.profile?.banner}`
                        }
                        alt="banner"
                    />
                </div>
                <div
                    className="endcover_check"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                >
                    <BsCheckLg className="thanks_checkicon" />
                </div>
                <div className="order_successfulcontent">
                    <div>
                        <div className="end_content">
                            <h2>Order Placed Successfully</h2>
                            <p>
                                Thank You! Your order has been submitted, your
                                server will take care of the rest.
                            </p>
                        </div>
                        <div className="end_viewreceipt">
                            <div className="view_receiptlist">
                                <h3 className="receiptlist_heading">
                                    Your Total Order:
                                </h3>
                                <span className="receiptlist_heading">
                                    {/* {curr}&nbsp;{totalno.toFixed(2)} */}
                                    {currency?.replace(/-/g, " ")} {totalno}
                                </span>
                            </div>
                            <Link to={`/menu/${currentVenue}/${zone}/receipt`}>
                                <div className="view_receiptlist view_receiptwrap">
                                    <div>
                                        <img
                                            src="/Images/iconI.svg"
                                            alt="icon"
                                        />
                                        <span className="view_receipt">
                                            View receipt
                                        </span>
                                    </div>
                                    <HiOutlineChevronRight />
                                </div>
                            </Link>
                            <Link to={`/menu/${currentVenue}/${zone}/feedback`}>
                                <div className="view_receiptlist">
                                    <div>
                                        <img
                                            src="/Images/icon2.svg"
                                            alt="icon"
                                        />
                                        <span>Share feedbacks</span>
                                    </div>
                                    <HiOutlineChevronRight />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="end_btn">
                        <Link
                            to={`/menu/${currentVenue}/${
                                zone == "guest-request" ? "in-room" : zone
                            }`}
                        >
                            <AppButton
                                btnType="black-outlined"
                                data-aos="fade-in"
                                data-aos-anchor-placement="bottom-center"
                            >
                                Back To Menu
                            </AppButton>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
