import { useRef, useState, useEffect } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

import { StateType } from "../../store/Reducer/index.reducer";

import Pricing from "../Pricing";

import { HiPlusSm } from "react-icons/hi";
import { HomeIMAGE_BASE_URL, MEDIA_IMAGE_URL } from "../../constant";
import { getAllDetailProduct } from "../../store/api/detailproduct";
import { getOrderReceipt } from "../../store/api/receipt";

const Receipt = () => {
    const dispatch = useDispatch<any>();
    const { id, zone }: any = useParams();

    const ProductL = useSelector(
        (state: any) => state?.order?.order?.data?.order
    );
    const currency = useSelector(
        (state: StateType) => state.category?.categoryRes?.currency
    );

    const product = JSON.parse(ProductL.order);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };

    const price = {
        product: product,
        total: ProductL.total,
        subTotal: ProductL.subtotal,
        containerCharge: product.containerCharge,
        tax: product.tax,
        venueCharge: ProductL.venue_charge,
    };
    const handleReceipt = () => {
        dispatch(getOrderReceipt(ProductL.id, ProductL.restaurant_id));
    };
    let ref = useRef(null);
    const homeData = useSelector((state: StateType) => state.profile?.profile);
    const receiptData = useSelector(
        (state: StateType) => state.receipt.receipt
    );
    const today = Date.now();
    const date = `${new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(today)}`;

    return (
        <div className="receipt_wrapper">
            <div className="detailmenu__arrow" onClick={goBack}>
                <BsArrowLeftShort className="arrow-left" />
            </div>
            <div ref={ref} className="receipt_container">
                <div className="receipt-logo">
                    <img
                        src={
                            homeData?.data?.profile?.banner == "/images/banner/"
                                ? "/Images/catcover.png"
                                : `${MEDIA_IMAGE_URL}/images/banner/${homeData?.data?.profile?.banner}`
                        }
                        alt="banner"
                    />
                    <div>{date}</div>
                </div>
                <div className="receipt-logoafterdownload">
                    <img
                        src={`${MEDIA_IMAGE_URL}/images/logo/${homeData?.data?.profile?.logo}`}
                        alt="banner"
                    />
                    <div>{date}</div>
                </div>
                <div className="order-receipt">
                    <h2>Order Receipt</h2>
                    <div className="receipt_productcontainer">
                        {product?.cart?.map((data: any, index: number) => {
                            return (
                                <div className="receipt-product" key={index}>
                                    <div>
                                        <h4>
                                            {data?.name}
                                            <sub>
                                                <HiPlusSm className="hiplus_icon" />
                                                {data?.quantity}
                                            </sub>
                                        </h4>

                                        <div className="receipt_productprice">
                                            {currency?.replace(/-/g, " ")}{" "}
                                            {data?.unitPrice}
                                        </div>
                                    </div>
                                    <div className="receipt_toppingsection">
                                        {data?.topping?.map(
                                            (
                                                toppingproduct: any,
                                                i: number
                                            ) => {
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <h5>
                                                                {
                                                                    toppingproduct?.name
                                                                }
                                                            </h5>
                                                            <div className="receipt_productquantity">
                                                                {
                                                                    toppingproduct?.quantity
                                                                }
                                                            </div>
                                                            <div className="receipt_productqty">
                                                                {currency?.replace(
                                                                    /-/g,
                                                                    " "
                                                                )}{" "}
                                                                {
                                                                    toppingproduct?.price
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {zone != "guest-request" && (
                        <Pricing price={price} receipt={true} />
                    )}
                </div>
            </div>
            {/* <ReactToPrint
                documentTitle="ORDR"
                trigger={() => ( */}
            <div className="download_receipt" onClick={handleReceipt}>
                <img src="/Images/download-icon.svg" alt="download-icon" />
                <span>Download Receipt</span>
            </div>
            {/* )}
                pageStyle="padding:180px"
                content={() => ref.current}
            /> */}
        </div>
    );
};

export default Receipt;
