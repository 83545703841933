import { useEffect, useState } from "react";
import { AiFillFire, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MEDIA_IMAGE_URL, PRODCUCTIMAGE_BASE_URL } from "../../constant";
import { displayLoader } from "../../store/Action/loader.action";
import { getCart, handleAddToCart } from "../../store/api/cart";
import { getAllCategories } from "../../store/api/category";
import { getAllDetailProduct } from "../../store/api/detailproduct";
import { StateType } from "../../store/Reducer/index.reducer";
import { ToppingType } from "../../store/Type/detailproduct.type";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";

const Index = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<any>();
    const { id, currentVenue, zone }: any = useParams();
    const navigate = useNavigate();
    const date = new Date();

    const singleMenuDetails = useSelector(
        (state: StateType) => state.detailProduct.detailProduct
    );
    const currency = useSelector(
        (state: StateType) => state.category.categoryRes.currency
    );
    const loader = useSelector(
        (state: StateType) => state.detailProduct.loader
    );
    const [quantity, setQuantity] = useState(1);
    const [toppings, setToppings] = useState<Array<string>>([]);
    const [isTouched, setIsTouched] = useState(false);

    const handleTouchStart = () => {
        setIsTouched(true);
    };

    const handleTouchEnd = () => {
        setIsTouched(false);
    };

    const goBack = () => {
        navigate(-1);
    };
    const device_time =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    useEffect(() => {
        id && id.length !== "" && dispatch(getAllDetailProduct(id));
        dispatch(displayLoader(true));
        dispatch(getCart());
        dispatch(getAllCategories(currentVenue, zone, device_time));
    }, [dispatch, id]);

    const handleAdd = () => {
        const data = {
            productId: id,
            quantity: quantity,
            toppingId: toppings,
            zone: zone,
        };

        dispatch(handleAddToCart(data, true, navigate));
    };
    const updateQuantity = (qty: number) => {
        setQuantity((prev) => prev + qty);
    };
    const handleSelect = (toppingitem: ToppingType) => {
        toppings.includes(toppingitem.id)
            ? setToppings((prev) =>
                  prev.filter((item) => item !== toppingitem.id)
              )
            : setToppings((prev) => [...prev, toppingitem.id]);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    const checkTopping = (data: any) => {
        let toppingId = toppings?.find((x: any) => x === data.id);
        if (toppingId !== undefined) {
            return true;
        }
        return false;
    };

    return (
        <div className="whole_wrapper">
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="detailmenu_wrapper">
                    {singleMenuDetails?.photo && (
                        <div className="detailmenu__image">
                            <img
                                src={`${MEDIA_IMAGE_URL}/images/product/${singleMenuDetails?.photo}`}
                            />
                        </div>
                    )}
                    <div className="detailmenu__arrow" onClick={goBack}>
                        <BsArrowLeftShort className="arrow-left" />
                    </div>
                    <div
                        className={
                            singleMenuDetails?.photo
                                ? "detailitem_container"
                                : "detailitem_container inactive"
                        }
                    >
                        <div className="detailitem__content">
                            <div className="d-flex j-content m-8">
                                {singleMenuDetails?.is_popular == 1 && (
                                    <div className="menu_status">
                                        <span>Popular</span>
                                    </div>
                                )}
                                {singleMenuDetails?.is_hot == 1 && (
                                    <div className="fire">
                                        <AiFillFire className="fire-icon" />
                                    </div>
                                )}
                            </div>

                            <h4>{singleMenuDetails?.name}</h4>
                            <p className="detail__desc">
                                {singleMenuDetails?.description}
                            </p>
                            <div className="detail_price_GVD">
                                <p className="detail__price">
                                    {currency?.replace(/-/g, " ")}
                                    {singleMenuDetails?.mrp}
                                </p>

                                <div className="menu__GVD">
                                    {singleMenuDetails?.tags?.map(
                                        (tag, index) => {
                                            return (
                                                <>
                                                    <span key={index}>
                                                        {tag}
                                                    </span>
                                                </>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* {singleMenuDetails?.topping?.length > 0 ? (
                            <div className="choice_container">
                                <span>Choice of</span>
                                <span>Required</span>
                            </div>
                        ) : (
                            ""
                        )} */}
                        <div className="item__toppings">
                            {Array.isArray(singleMenuDetails?.topping) &&
                                singleMenuDetails?.topping?.map(
                                    (data: any, index: number) => {
                                        return (
                                            <>
                                                <div className="topping_title">
                                                    {data.name}
                                                </div>
                                                {data?.topping?.map(
                                                    (
                                                        datatopping: any,
                                                        i: number
                                                    ) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="itemtopping__content"
                                                                    onClick={() => {
                                                                        handleSelect(
                                                                            datatopping
                                                                        );
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="topping"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {/* {text_truncate(
                                                                                {
                                                                                    str: datatopping.name,
                                                                                    length: 25,
                                                                                    ending: null,
                                                                                }
                                                                            )} */}
                                                                            {
                                                                                datatopping.name
                                                                            }
                                                                        </span>

                                                                        <span>
                                                                            +
                                                                            {
                                                                                datatopping.mrp
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <label className="detail__checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkTopping(
                                                                                datatopping
                                                                            )}
                                                                        />
                                                                        <div
                                                                            className="checkmark"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                e.stopPropagation()
                                                                            }
                                                                        ></div>
                                                                    </label>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                )}
                                            </>
                                        );
                                    }
                                )}
                            {singleMenuDetails.can_order == 1 && (
                                <div>
                                    {singleMenuDetails.sold != 0 && (
                                        <div className="detailmenu_addorder">
                                            <div className="incdec__btn">
                                                <div
                                                    onClick={() =>
                                                        updateQuantity(
                                                            quantity > 1
                                                                ? -1
                                                                : 0
                                                        )
                                                    }
                                                >
                                                    <AiOutlineMinus className="incdec_icon" />
                                                </div>
                                                <div>{quantity}</div>
                                                <div
                                                    onClick={() =>
                                                        updateQuantity(
                                                            quantity > 0 ? 1 : 0
                                                        )
                                                    }
                                                >
                                                    <AiOutlinePlus className="incdec_icon" />
                                                </div>
                                            </div>

                                            <button
                                                onClick={() => handleAdd()}
                                                className={`touch-button ${
                                                    isTouched ? "touched" : ""
                                                }`}
                                                onTouchStart={handleTouchStart}
                                                onTouchEnd={handleTouchEnd}
                                                // disabled={
                                                //     singleMenuDetails.sold == 0
                                                //         ? true
                                                //         : false
                                                // }
                                                // className={
                                                //     singleMenuDetails.sold == 0
                                                //         ? "btn-opacityactive"
                                                //         : "btn-opacity"
                                                // }
                                            >
                                                {t(
                                                    "default:common.buttons.addToOrder"
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
