import { categoryAction, loaderAction } from "../const";
import { CategoryResType, CategoryType } from "../Type/category.type";
import {
    directoryCategoryDataType,
    directoryCategoryType,
} from "../Type/directoryCategory.type";

export interface IDirectoryReducer {
    directoryCategory: directoryCategoryType;
    loader: boolean;
}
const inititalState: IDirectoryReducer = {
    directoryCategory: {} as directoryCategoryType,
    loader: false,
};

const directoryReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case categoryAction.GET_DIRECTORY_CATEGORY:
            return {
                ...state,
                directoryCategory: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default directoryReducer;
