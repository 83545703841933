import { Dispatch } from "redux";
import api from ".";
import { getCategory } from "../Action/category.action";
import { displayLoader } from "../Action/loader.action";

export const getAllCategories =
    (slug: any, zone: any, device_time: any) => (dispatch: Dispatch) => {
        api.get(
            `/category/list/${slug}?zone=${zone}&device_time=${device_time}`
        )
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(
                    getCategory({
                        ...response?.data?.data.categories[0],
                        currency: response.data?.data?.currency,
                    })
                );
            })
            .catch((err) => {
                dispatch(displayLoader(false));
            });
    };
const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "yuan":
            return "CNY";
        case "ringgit":
            return "MYR";
        case "thai-baht":
            return "THB";
        case "myanmar-kyat":
            return "MMK";
        default:
            return "THB";
    }
};
