import { categoryAction } from "../const";
export const getCategory = (data: any) => {
    return {
        type: categoryAction.GET_CATEGORY,
        payload: data,
    };
};

export const getGuestReqCategory = (data: any) => {
    return {
        type: categoryAction.GET_HOUSEKEEPING_CATEGORY,
        payload: data,
    };
};

export const getDirectoryCategory = (data: any) => {
    return {
        type: categoryAction.GET_DIRECTORY_CATEGORY,
        payload: data,
    };
};
