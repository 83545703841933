import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCategories } from "../../store/api/category";
import { StateType } from "../../store/Reducer/index.reducer";
import { useTranslation } from "react-i18next";

const Pricing = (props: any) => {
    const { t } = useTranslation("pricing");
    const { currentVenue } = useParams();

    const {
        product: Product,
        total,
        subTotal,
        venueCharge,
        tax,
        containerCharge,
        service_charge,
        tax_amount,
        total_before_tax,
    } = useSelector((state: StateType) => state.cart);
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const { receipt, price } = props;
    const currency = useSelector(
        (state: StateType) => state.category?.categoryRes?.currency
    );

    return (
        <div className="pricing">
            <>
                <div className="price subtotal">
                    <div>{t("subtotal")}</div>
                    <div>
                        {currency?.replace(/-/g, " ")}
                        <span>{receipt ? price?.subTotal : subTotal}</span>
                    </div>
                </div>
                {homeData?.data?.profile?.type == 3 && receipt
                    ? price?.containerCharge > 0 && (
                          <div className="price venue-service">
                              <div>{t("containerCharge")}</div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{price?.containerCharge}</span>
                              </div>
                          </div>
                      )
                    : homeData?.data?.profile?.type == 3 &&
                      containerCharge > 0 && (
                          <div className="price venue-service">
                              <div>{t("containerCharge")}</div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{containerCharge}</span>
                              </div>
                          </div>
                      )}
                {receipt
                    ? price?.serviceCharge > 0 && (
                          <div className="price venue-service">
                              <div>
                                  {t("serviceCharge")}({price?.venueCharge}%)
                              </div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  {"  "}
                                  <span>{price?.serviceCharge}</span>
                              </div>
                          </div>
                      )
                    : venueCharge > 0 && (
                          <div className="price venue-service">
                              <div>
                                  {t("serviceCharge")}({venueCharge}%)
                              </div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{service_charge}</span>
                              </div>
                          </div>
                      )}
                {receipt
                    ? price?.venueCharge > 0 && (
                          <div className="price venue-service">
                              <div>{t("beforeVat")}</div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{total_before_tax}</span>
                              </div>
                          </div>
                      )
                    : venueCharge > 0 && (
                          <div className="price venue-service">
                              <div>{t("beforeVat")}</div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{total_before_tax}</span>
                              </div>
                          </div>
                      )}

                {receipt
                    ? price?.tax > 0 && (
                          <div className="price venue-service">
                              <div>
                                  {t("tax")}({price?.tax}%)
                              </div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{tax_amount}</span>
                              </div>
                          </div>
                      )
                    : tax > 0 && (
                          <div className="price venue-service">
                              <div>
                                  {t("tax")}({tax}%)
                              </div>
                              <div>
                                  {currency?.replace(/-/g, " ")}
                                  <span>{tax_amount}</span>
                              </div>
                          </div>
                      )}
                <div className="total">
                    <div>{t("total")}</div>
                    <div>
                        {currency?.replace(/-/g, " ")}
                        {"  "}
                        {receipt ? price?.total : total}
                    </div>
                </div>
            </>
        </div>
    );
};

export default Pricing;
