const categoryLangEng = {
    orderUnavailable: "Ordering not available",
    room: "Room",
    tee: "Tee",
    table: "Table",
    selectRoom: "Select Room Number",
    selectTee: "Select Tee Number",
    selectTable: "Select Table Number",
    roomNumTitle: "Please enter your Room number",
    whereSeated: "Where are you seated?",

    tableNum: "Table Number",
    roomNum: "Room Number",
    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
};

const categoryLangThai = {
    orderUnavailable: "Ordering not available",
    room: "Room",
    tee: "Tee",
    table: "Table",
    selectRoom: "Select Room Number",
    selectTee: "Select Tee Number",
    selectTable: "Select Table Number",
    tableNum: "Table Number",
    roomNum: "Room Number",
    validation: {
        roomNum: "Please enter correct room number!",
        teeNum: "Please enter correct tee number!",
        tableNum: "Please enter correct table number!",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
};

export const categoryLang = {
    eng: categoryLangEng,
    thai: categoryLangThai,
};
