import { detailProductAction, loaderAction } from "../const";
import { DetailProductType } from "../Type/detailproduct.type";
import { DirectoryDetailType } from "../Type/directoryDetail.type";
export interface DetailDirectoryItemReducer {
    detailDirectoryItem: DirectoryDetailType;
    loader: boolean;
}
const initialState: DetailDirectoryItemReducer = {
    detailDirectoryItem: {} as DirectoryDetailType,
    loader: true,
};
const detailDirectoryReducer = (
    state = initialState,
    { type, payload }: any
) => {
    switch (type) {
        case detailProductAction.GET_DETAILDIRECTORYITEM:
            return {
                ...state,
                detailDirectoryItem: payload,
                loader: false,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default detailDirectoryReducer;
