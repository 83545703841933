import { feedbackAction, loaderAction } from "../const";

import { FeedbackType } from "../Type/feedback.type";
export interface FeedbackReducer {
    feedback: FeedbackType;
    loader: boolean;
}
const initialState: FeedbackReducer = {
    feedback: {} as FeedbackType,
    loader: false,
};
const feedbackReducer = (state = initialState, { type, payload }: any) => {
    switch (type) {
        case feedbackAction.FEEDBACK_ACTION:
            return {
                ...state,
                feedback: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};
export default feedbackReducer;
