import { Dispatch } from "redux";
import api from ".";
import { getDirectoryCategory } from "../Action/category.action";
import { displayLoader } from "../Action/loader.action";

export const getAllDirectoryCategories =
    (slug: any, zone: string) => (dispatch: Dispatch) => {
        api.get(`/digital-directory/category-api/${slug}?zone=${zone}`)
            .then((response) => {
                dispatch(displayLoader(false));
                dispatch(
                    getDirectoryCategory({
                        ...response?.data,
                        currency: response.data?.data?.currency,
                    })
                );
            })
            .catch((err) => {
                dispatch(displayLoader(false));
            });
    };
