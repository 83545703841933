import React, { useEffect, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { Drawer, Button, Space, Radio } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import type { RadioChangeEvent } from "antd/es/radio";
import { IoMdClose } from "react-icons/io";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileApi } from "../../store/api/resturantProfile";
import { getAllCategories } from "../../store/api/category";
import { HomeIMAGE_BASE_URL } from "../../constant";
import { StateType } from "../../store/Reducer/index.reducer";
import { device_time } from "../Category";
import { useTranslation } from "react-i18next";
import { switchI18nLanguage } from "../../i18n";

interface Props {
    showNavMenu?: boolean;
    showArrow?: boolean;
    showDineIn?: boolean;
    showLogo?: boolean;
    venue?: string;
}

const Navbar: React.FC<Props> = (props) => {
    const dispatch: any = useDispatch();
    const { zone } = useParams();
    const location = window.location.pathname.includes("/guest-request");
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] =
        useState<DrawerProps["placement"]>("left");
    const homeData = useSelector((state: StateType) => state.profile.profile);
    const [langType, setLangType] = useState<"eng" | "thai">();

    const goBack = () => {
        navigate(-1);
    };
    const date = new Date();

    useEffect(() => {
        let languageType = i18n.language;
        setLangType(languageType === "eng" ? "eng" : "thai");
    }, []);

    useEffect(() => {
        if (props.venue != undefined) {
            dispatch(getProfileApi(props.venue, zone));
            dispatch(getAllCategories(currentVenue, zone, device_time));
        }
    }, [props.venue]);
    const showDrawer = () => {
        setVisible(true);
        setDrawerOpen(true);
    };
    const onChange = (e: RadioChangeEvent) => {
        setPlacement(e.target.value);
    };
    const { id, currentVenue } = useParams();
    const onClose = () => {
        setVisible(false);
    };

    const handleLangChange = (value: "eng" | "thai") => {
        setLangType(value);
        switchI18nLanguage(value);
    };

    return (
        <>
            <div className="navbar">
                {props.showNavMenu && (
                    <div className="navmenu">
                        <Space>
                            <HiMenuAlt1 fontSize={24} onClick={showDrawer} />
                        </Space>

                        <div className="navmenu_items">
                            {/* <button
                            type="button"
                            style={{backgroundColor: languageType === "eng" ? "skyblue" : "white", color: languageType === "eng" ? "white" : "black"}}
                            onClick={() => switchI18nLanguage("eng")}
                            >
                            Eng
                            </button>
                            <button
                            type="button"
                            style={{backgroundColor: languageType === "thai" ? "skyblue" : "white", color: languageType === "thai" ? "white" : "black"}}
                            onClick={() => switchI18nLanguage("thai")}
                            >
                            Thai
                            </button> */}

                            {/* <select
                                className="navmenu_items_select"
                                onChange={(e) => {
                                    handleLangChange(e?.target?.value === "eng" ? "eng" : "thai")
                                }}
                                name="type"
                                value={langType}
                            >
                                <option value={"eng"} selected={langType === "eng"}>EN</option>
                                <option value={"thai"} selected={langType === "thai"}>TH</option>
                            </select> */}
                        </div>

                        <Drawer
                            placement={placement}
                            width={500}
                            onClose={onClose}
                            visible={visible}
                            className="menu__sidebar"
                        >
                            <div className="close-icon">
                                <IoMdClose onClick={onClose} />
                            </div>
                            <div className="drawer__menucontent">
                                <div className="menu-sidebar__img">
                                    <img
                                        src={
                                            !homeData?.data?.profile?.logo
                                                ? `${homeData?.data?.profile?.logo}`
                                                : "/Images/ordr-logo.svg"
                                        }
                                        // src="/Images/ordr-logo.svg"
                                        alt="logo"
                                    />
                                </div>

                                <ul>
                                    {/* <li>Start a tab</li> */}
                                    <li>
                                        <Link
                                            className="side_link"
                                            to={`/menu/${props.venue}/feedback`}
                                        >
                                            Leave a feedback
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className="side_link"
                                            href="https://myordr.com/home/"
                                            target="_blank"
                                        >
                                            Learn more about ORDR.
                                        </a>
                                    </li>
                                    {/* <Link className="nav-link" to="/order">
                                        <li>View orders & receipts</li>
                                    </Link>

                                    <Link
                                        className="nav-link"
                                        to="/otp-confirmation"
                                    >
                                        <li> Log in</li>
                                    </Link> */}
                                </ul>
                            </div>
                            <div className="sidebar__content">
                                <p className="content-II">
                                    {homeData?.data?.profile?.address},
                                    {homeData?.data?.profile?.country}
                                </p>
                                <p className="content-II">
                                    {homeData?.data?.profile?.phone}
                                </p>
                                <p className="content-II">
                                    {homeData?.data?.profile?.email}
                                </p>
                                {/* <div className="menu__code">
                                    <span>EN</span>
                                    <span>DE</span>
                                    <span>FR</span>
                                    <span>EL</span>
                                    <span>CN</span>
                                    <span>ES</span>
                                    <span>IT</span>
                                </div> */}
                            </div>
                        </Drawer>
                    </div>
                )}
                {props.showArrow && (
                    <div className="left-arrow">
                        <BsArrowLeft
                            fontSize={16}
                            className="left-arrow__icon"
                            onClick={goBack}
                        />
                    </div>
                )}
                {props.showLogo && (
                    <div className="nav-venuename">
                        <Link
                            to={
                                location
                                    ? `/menu/${props.venue}/in-room`
                                    : props.venue
                                    ? `/menu/${props.venue}/${zone}`
                                    : ""
                            }
                        >
                            {homeData?.data?.profile?.name}
                        </Link>
                    </div>
                )}
                {props.showDineIn && <div className="dinein">{zone}</div>}
            </div>
        </>
    );
};

export default Navbar;
