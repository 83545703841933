import React, { useEffect, useState } from "react";
import Drawer from "../../components/DrawerForm";
import SelectTable from "../../components/SelectTable";
import Navbar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../store/Reducer/index.reducer";
import { displayLoader } from "../../store/Action/loader.action";
import { getCart } from "../../store/api/cart";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../components/ResturantProfile";

const Index = () => {
    const { t } = useTranslation("order");
    const { currentVenue, zone } = useParams();
    let query = useQuery();
    const dispatch = useDispatch<any>();
    const [visible, setVisible] = useState(false);
    const [tablenum, setTableNum] = useState<string | number | null>(null);
    const [inputTableNum, setInputTableNum] = useState<string | number | null>(
        null
    );
    const [validationMsgTwo, setValidationMsgTwo] = useState("");
    const [validationMsgThree, setValidationMsgThree] = useState("");
    const [inputName, setInputName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [name, setName] = useState("");
    const cart = useSelector((state: StateType) => state.cart);
    const tableData = useSelector((state: StateType) => state.order);

    const homeData = useSelector((state: StateType) => state.profile.profile);

    useEffect(() => {
        if (query.get("key")) {
            setInputTableNum(query.get("key") ?? "");
            setTableNum(query.get("key") ?? "");
        }
    }, [query.get("key")]);

    const onSubmit = () => {
        if (
            homeData?.data?.profile?.table?.filter(
                (data: any) => data?.key == inputTableNum
            )?.length
        ) {
            setTableNumber(inputTableNum);
        }
        if (!inputName) {
            setValidationMsgTwo("Please provide your fullname");
            return;
        }
        if (!phoneNum) {
            setValidationMsgThree("Please enter your contact number");
        }
        {
            setPhoneNum(phoneNum);
        }
        {
            setTableNumber(inputTableNum);
        }
    };
    const onChangeTwo = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgTwo("Please provide your fullname");
            } else {
                setValidationMsgTwo("");
            }
            setInputName(e.target.value);
        }
    };
    const onChangeThree = (e: any) => {
        {
            if (!e.target.value) {
                setValidationMsgThree("Please enter your contact number");
            } else {
                setValidationMsgThree("");
            }
            setPhoneNum(e.target.value);
        }
    };

    const showDrawer = () => {
        setVisible(true);
    };
    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getCart());
    }, [dispatch]);

    let table = sessionStorage.getItem("tablenum");
    let tablename = sessionStorage.getItem("inputName");
    let phonenum = sessionStorage.getItem("phone");

    React.useEffect(() => {
        if (table) {
            let newtable = JSON.parse(table);
            setTableNum(newtable);
        }
        if (tablename) {
            setName(tablename);
        }
        if (phonenum) {
            setPhoneNum(phonenum);
        }
    }, [table, tablename, phonenum]);

    const setTableNumber = (tableNum: string | number | null) => {
        setInputTableNum(tableNum);
        sessionStorage.setItem("tablenum", JSON.stringify(tableNum));
        sessionStorage.setItem("inputName", inputName);
        sessionStorage.setItem("phone", phoneNum);
    };

    return (
        <>
            <Navbar showArrow={true} showDineIn venue={currentVenue} />
            {cart.loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="order-wrapper">
                    {!tablenum ? (
                        <>
                            <h2>{t("orderDetails")}</h2>
                            <div className="info-content">
                                <div className="table_info">
                                    {zone == "in-room" ||
                                    zone == "guest-request"
                                        ? `${t("selectRoomNum")}`
                                        : `${t("selectTableNum")}`}
                                </div>
                                <div
                                    className="table_info table_selectoption"
                                    onClick={showDrawer}
                                >
                                    {t("select")}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="choosed-table_section">
                                <span>
                                    {/* {homeData?.data?.profile?.type == 2
                                        ? t("roomNum")
                                        : homeData?.data?.profile?.type == 4
                                        ? t("roomNum")
                                        : homeData?.data?.profile?.type == 3
                                        ? t("teeNum")
                                        : t("tableNum")} */}
                                    {zone == "in-room" ||
                                    zone == "guest-request"
                                        ? t("roomNum")
                                        : t("tableNum")}
                                </span>
                                <div className="choosedtable__btn">
                                    <button onClick={showDrawer}>
                                        {/* {homeData?.data?.profile?.type == 2
                                            ? t("room")
                                            : homeData?.data?.profile?.type == 4
                                            ? t("room")
                                            : homeData?.data?.profile?.type == 3
                                            ? t("tee")
                                            : t("table")}{" "} */}
                                        {zone == "in-room" ||
                                        zone == "guest-request"
                                            ? t("room")
                                            : t("table")}{" "}
                                        {tablenum}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <SelectTable
                        cart={cart}
                        tablenum={tablenum}
                        phonenum={phoneNum}
                        inputName={name}
                        disabledButton={tablenum ? false : true}
                    />
                </div>
            )}
            <Drawer
                title={
                    zone == "in-room" || zone == "guest-request"
                        ? t("roomNumTitle")
                        : t("whereSeated")
                }
                label={
                    zone == "in-room" || zone == "guest-request"
                        ? t("roomNum")
                        : t("tableNum")
                }
                placeholder={
                    zone == "in-room" || zone == "guest-request"
                        ? `${t("placeholder.roomNum")}`
                        : `${t("placeholder.tableNum")}`
                }
                visible={visible}
                onClose={() => setVisible(false)}
                onSubmit={onSubmit}
                onChangeOne={(e) => setInputTableNum(e.target.value)}
                onChangeTwo={(e) => onChangeTwo(e)}
                onChangeThree={(e) => onChangeThree(e)}
                validationMsgOne={
                    !inputTableNum ||
                    homeData?.data?.profile?.table?.filter(
                        (data: any) => data.key == inputTableNum
                    )?.length
                        ? ""
                        : // `${
                          //       homeData?.data?.profile?.type == 2
                          //           ? t("validation.roomNum")
                          //           : homeData?.data?.profile?.type == 4
                          //           ? t("validation.roomNum")
                          //           : homeData?.data?.profile?.type == 3
                          //           ? t("validation.teeNum")
                          //           : t("validation.tableNum")
                          //   }`
                          `
                        ${
                            zone == "in-room" || zone == "guest-request"
                                ? t("validation.roomNum")
                                : t("validation.tableNum")
                        }`
                }
                validationMsgTwo={validationMsgTwo}
                validationMsgThree={validationMsgThree}
                buttondisabled={
                    homeData?.data?.profile?.type == 3
                        ? homeData?.data?.profile?.table?.filter(
                              (data: any) => data.key == inputTableNum
                          )?.length &&
                          inputName.length &&
                          phoneNum
                            ? false
                            : true
                        : !homeData?.data?.profile?.table?.filter(
                              (data: any) => data.key == inputTableNum
                          )?.length
                }
            />
        </>
    );
};

export default Index;
