import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { displayLoader } from "../../store/Action/loader.action";
import { StateType } from "../../store/Reducer/index.reducer";
import { useTranslation } from "react-i18next";
import { MEDIA_IMAGE_URL } from "../../constant";
import Navbar from "../../components/Navbar";
import Loader from "../../components/Loader";
import DrawerForm from "../../components/DrawerForm";
import CategoryBlock from "../../components/Category/categoryBlock";
import Footer from "../../components/Footer";
import { getAllDirectoryCategories } from "../../store/api/directoryCategory";
import ListingBlock from "../../components/ListingMenu/listingBlock";

const DirectoryCategory = () => {
    const { t } = useTranslation("category");
    const dispatch: any = useDispatch();
    const location = window.location.pathname.includes("/guest-request");
    const { currentVenue, zone }: any = useParams();

    const homeData = useSelector((state: StateType) => state.profile?.profile);
    const directoryCat = useSelector(
        (state: StateType) => state.directoryCategory?.directoryCategory
    );
    const loader = useSelector(
        (state: StateType) => state.directoryCategory?.loader
    );

    useEffect(() => {
        dispatch(displayLoader(true));
        dispatch(getAllDirectoryCategories(currentVenue, zone));
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
    }, []);

    return (
        <>
            <Navbar showLogo showNavMenu venue={currentVenue} />
            {loader ? (
                <Loader classType="loading-spinner" />
            ) : (
                <div className="whole_wrapper directory_categorywrapper">
                    <div className="category container">
                        <div className="category-cover">
                            <img
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "/Images/catcover.png";
                                }}
                                src={
                                    homeData?.data?.profile?.banner ==
                                    "/images/user/"
                                        ? "/Images/catcover.png"
                                        : `${MEDIA_IMAGE_URL}/images/banner/${homeData?.data?.profile?.banner}`
                                }
                                alt="category-cover"
                            />
                        </div>
                        <div className="delivery_content">
                            <div className="delivery_category">
                                {location}
                                Digital Directory
                                {/* {t("default:common.buttons.guestRequest")} */}
                            </div>
                        </div>

                        <div className="category_container">
                            {directoryCat?.data?.map((item, index) => {
                                return (
                                    <>
                                        {item?.products?.length > 0 && (
                                            <div
                                                className="food-items"
                                                key={index}
                                            >
                                                <div
                                                    key={item.name}
                                                    className="directory-item"
                                                >
                                                    {item?.products?.length >
                                                        0 && (
                                                        <div className="directoryitem-title">
                                                            {item.name}
                                                        </div>
                                                    )}

                                                    {item?.products?.length >
                                                        0 && (
                                                        <div className="menuitems_wrap">
                                                            {item.products.map(
                                                                (
                                                                    product,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <Link
                                                                            key={
                                                                                index
                                                                            }
                                                                            to={`/menu/${currentVenue}/${zone}/directory-detail/${product.id}`}
                                                                            className="items_link menu_itemslink"
                                                                        >
                                                                            <ListingBlock
                                                                                imgsrc={
                                                                                    product.image
                                                                                        ? `${MEDIA_IMAGE_URL}/images/digital-product/${product.image}`
                                                                                        : "/Images/noimg-1.svg"
                                                                                }
                                                                                description={
                                                                                    product.description
                                                                                }
                                                                                item_name={
                                                                                    product.name
                                                                                }
                                                                                item_mrp={
                                                                                    product.price
                                                                                }
                                                                                price={
                                                                                    true
                                                                                }
                                                                            />
                                                                        </Link>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default DirectoryCategory;
