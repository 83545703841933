import { notification } from "antd";

import { Dispatch } from "redux";
import api from ".";
import { getFeedback } from "../Action/feedback.action";

import "react-toastify/dist/ReactToastify.css";
import { displayLoader } from "../Action/loader.action";

export interface FeedbackBodyType {
    name?: string;
    email?: string;
    message?: string;
    resturant_id?: number;
}

export const ApiFeedbackOrder =
    (
        data: any,
        props: { currentVenue: string | undefined; zone: any; navigate: any }
    ) =>
    (dispatch: Dispatch) => {
        const { currentVenue, zone, navigate } = props;
        api.post(`/review`, data)
            .then((response: any) => {
                const key = "updatable";
                dispatch(displayLoader(true));
                dispatch(getFeedback(response));
                currentVenue &&
                    navigate(`/menu/${currentVenue}/category`, {
                        replace: true,
                    });
                notification.open({
                    key,
                    duration: 1,
                    message: "Feedback sent",
                });
            })
            .catch((err) => {});
    };
