const FeedbackLangEng = {
    sendFeedback: "Send Us Your Feedback!",
    form: {
        name: {
            label: "Name",
            placeholder: "Enter name here"
        },
        email: {
            label: "Email",
            placeholder: "Enter email here"
        },
        message: {
            label: "Message",
            placeholder: "Enter message here"
        },
    }
};

const FeedbackLangThai = {
    sendFeedback: "Send Us Your Feedback!",
    form: {
        name: {
            label: "Name",
            placeholder: "Enter name here"
        },
        email: {
            label: "Email",
            placeholder: "Enter email here"
        },
        message: {
            label: "Message",
            placeholder: "Enter message here"
        },
    }
};

export const feedbackLang = {
    eng: FeedbackLangEng,
    thai: FeedbackLangThai,
};
