import { detailProductAction } from "../const";

export const getDetailProduct = (data: any) => {
    return {
        type: detailProductAction.GET_DETAILPRODUCT,
        payload: data,
    };
};
export const getDetailDirectoryItem = (data: any) => {
    return {
        type: detailProductAction.GET_DETAILDIRECTORYITEM,
        payload: data,
    };
};
