import { loaderAction, tagAction } from "../const";
import { TagType } from "../Type/tag.type";

export interface TagReducer {
    tags: Array<TagType>;
}
const inititalState: TagReducer = {
    tags: [],
    // loader: false,
};

const tagReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case tagAction.GET_TAG:
            return {
                ...state,
                tags: payload,
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default tagReducer;
