const drawerFormLangEng = {
    validation: {
        roomNum: "Please enter your Room number",
        teeNum: "Please enter your Tee number",
        whereSeated: "Where are you seated?",
        provideFullName: "Please provide fullname on your booking",
        contactNum: "Please enter your contact number",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
    roomNum: "Room Number",
    teeNum: "Current Tee Number",
    tableNum: "Table Number",
    contactNum: "Contact Number (in case can't find you)",
};

const drawerFormLangThai = {
    validation: {
        roomNum: "Please enter your Room number",
        teeNum: "Please enter your Tee number",
        whereSeated: "Where are you seated?",
        tableNum: "Where are you seated?",
        provideFullName: "Please provide fullname on your booking",
        contactNum: "Please enter your contact number",
    },
    placeholder: {
        roomNum: "Current Room number",
        teeNum: "Current Tee number",
        tableNum: "Table Number",
        booking: "Full Name on booking",
        contactNum: "Contact Number",
    },
    roomNum: "Room Number",
    teeNum: "Current Tee Number",
    tableNum: "Table Number",
    contactNum: "Contact Number (in case can't find you)",
};

export const drawerFormLang = {
    eng: drawerFormLangEng,
    thai: drawerFormLangThai,
};
