import { Dispatch } from "redux";
import api from ".";
import { emptyCart } from "../Action/cart.action";
import { displayLoader } from "../Action/loader.action";
import { createOrder } from "../Action/order.action";
export interface OrderBodyType {
    resturant: number;
    subTotal: number;
    total: number;
    table: number;
    name: string;
    kitchen_note: string;
    phone: any;
}

export const ApiCreateOrder =
    (
        data: OrderBodyType,
        props: { currentVenue: string | undefined; zone: any; navigate: any }
    ) =>
    (dispatch: Dispatch) => {
        const { currentVenue, zone, navigate } = props;

        api.post(`/order/create`, data, {
            headers: {
                guestid: localStorage.getItem("session_id") || "",
            },
        })
            .then((response) => {
                dispatch(createOrder(response.data));
                dispatch(emptyCart());
                currentVenue &&
                    navigate(`/menu/${currentVenue}/${zone}/greeting`, {
                        replace: true,
                    });
                sessionStorage.removeItem("tablenum");
            })
            .catch((err) => {
                dispatch(displayLoader(false));
            });
    };
