import categoryReducer, { CategoryReducer } from "./category.reducer";
import { combineReducers } from "redux";
import menuReducer, { MenuReducer } from "./menu.reducer";
import detailProductReducer, {
    DetailProductReducer,
} from "./detailproduct.reducer";
import cartReducer, { CartReducar } from "./cart.reducer";
import tagReducer, { TagReducer } from "./tag.reducer";
import orderReducer, { OrderReducer } from "./order.reducer";
import feedbackReducer, { FeedbackReducer } from "./feedback.reducer";
import resturantProfileReducer, {
    ResturantProfileReducer,
} from "./resturantProfile.reducer";
import vLoginReducer, { LoginReducer } from "./Vadmin/vlogin.reducer";
import vSignupReducer, { SignupReducer } from "./Vadmin/vsignup.reducer";
import receiptReducer, { ReceiptReducer } from "./receipt.reducer";
import businessTypeReducer, {
    BusinessTypeReducer,
} from "./Vadmin/BusinessType.reducer";
import guestRequestReducer, {
    guestReqReducer,
} from "./guestReqCategory.reducer";
import directoryReducer, {
    IDirectoryReducer,
} from "./directoryCategory.reducer";
import detailDirectoryReducer, {
    DetailDirectoryItemReducer,
} from "./detailDirectory.reducer";
export interface StateType {
    category: CategoryReducer;
    guestReqCategory: guestReqReducer;
    directoryCategory: IDirectoryReducer;
    menu: MenuReducer;
    feedback: FeedbackReducer;
    detailProduct: DetailProductReducer;
    cart: CartReducar;
    profile: ResturantProfileReducer;
    tags: TagReducer;
    order: OrderReducer;
    vsignup: SignupReducer;
    vlogin: LoginReducer;
    googleauth: LoginReducer;
    receipt: ReceiptReducer;
    btype: BusinessTypeReducer;
    detailDirectoryItem: DetailDirectoryItemReducer;
}
const rootReducer = combineReducers({
    category: categoryReducer,
    guestReqCategory: guestRequestReducer,
    directoryCategory: directoryReducer,
    menu: menuReducer,
    detailProduct: detailProductReducer,
    feedback: feedbackReducer,
    cart: cartReducer,
    tags: tagReducer,
    profile: resturantProfileReducer,
    order: orderReducer,
    vlogin: vLoginReducer,
    googleauth: vLoginReducer,
    vsignup: vSignupReducer,
    receipt: receiptReducer,
    btype: businessTypeReducer,
    detailDirectoryItem: detailDirectoryReducer,
});
export default rootReducer;
