import { categoryAction, loaderAction } from "../const";
import { CategoryResType, CategoryType } from "../Type/category.type";

export interface guestReqReducer {
    guestReqCategory: Array<CategoryType>;
    guestReqCategoryRes: CategoryResType;
    loader: boolean;
}
const inititalState: guestReqReducer = {
    guestReqCategory: [],
    guestReqCategoryRes: {} as CategoryResType,
    loader: false,
};

const guestRequestReducer = (state = inititalState, { type, payload }: any) => {
    switch (type) {
        case categoryAction.GET_HOUSEKEEPING_CATEGORY:
            return {
                ...state,
                guestReqCategory: payload?.categories,
                guestReqCategoryRes: {
                    ...payload,
                    currency: payload?.currency ?? "$",
                },
            };
        case loaderAction.GET_LOADER:
            return {
                ...state,
                loader: payload,
            };
        default:
            return state;
    }
};

export default guestRequestReducer;
