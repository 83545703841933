import { useTranslation } from "react-i18next";
import text_truncate from "../TextTruncate";
interface IcategoryBlock {
    imgsrc: string;
    item_name: string;
    can_order?: any;
    end_time: string;
    location?: boolean;
}

const CategoryBlock = (props: IcategoryBlock) => {
    const { imgsrc, item_name, can_order, end_time, location } = props;
    const { t } = useTranslation("category");

    return (
        <>
            <div className="food-itemsimg">
                <img src={imgsrc} alt={item_name} />
            </div>
            {!location && (
                <div className="order_status">
                    <div>{can_order}</div>
                </div>
            )}
            <h2 className="item_name">
                {text_truncate({
                    str: item_name,
                    length: 18,
                    ending: null,
                })}
                {!location && (
                    <span className="availiable_time">
                        (Till {formatTime(end_time)})
                    </span>
                )}
            </h2>
        </>
    );
};

export default CategoryBlock;
export const formatTime = (timeString: any) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
};
