import { categoryLang } from "../components/Category/categoryLang";
import { drawerFormLang } from "../components/DrawerForm/drawerFormLang";
import { feedbackLang } from "../components/Feedback/feedbackLang";
import { listingMenuLang } from "../components/ListingMenu/listingMenuLang";
import { pricingLang } from "../components/Pricing/pricingLang";
import { restaurantProfileLang } from "../components/ResturantProfile/restaurantProfileLang";
import { orderLang } from "../pages/Order/orderLang";
import { commonThai } from "./modules/common";

const thai = {
  default: {
    common: {...commonThai}
  },
  restaurantProfile: {...restaurantProfileLang.thai},
  feedback: {...feedbackLang.thai},
  category: {...categoryLang.thai},
  listingMenu: {...listingMenuLang.thai},
  order: {...orderLang.thai},
  pricing: {...pricingLang.thai},
  drawerForm: {...drawerFormLang.thai}
};

export default thai;
